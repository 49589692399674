import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Multiselect from "multiselect-react-dropdown";
import { channellist } from "../../dataservices/model/channellist";
import { GetAllMarketplaces } from "../../dataservices/action/marketplace/marketplace";
import { GetOrderRuleModelType, GetOrderRuleAttributes, GetOrderRuleOperators, GetExistingOrderRules } from "../../dataservices/action/marketplace/marketplace";


interface Condition {
    type: string;
    attribute: string;
    condition: string;
    value: string;
}
interface TypeOption {
    id: number;
    name: string;
    operator: string;
    label: string;
}

interface DropdownOptions {
    types: TypeOption[];
    attributes: TypeOption[];  // Adjust accordingly if needed
    conditions: TypeOption[];  // Adjust accordingly if needed
    values: TypeOption[];
}
const OrderRulesComponent = () => {
    const history = useHistory();
    const [activeprodclass, setactiveprodclass] = useState(true);
    const [activeEprdclass, setactiveEprdclass] = useState(false);
    const [ruleName,setRuleName]=useState("");
    const [ruleType, setRuleType] = useState("");
    const [conditions, setConditions] = useState<Condition[]>([]);
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [code, setCode] = useState(["ALL", "ANY"]);
    const [selectedCode, setSelectedCode] = useState("ALL");
    const [isDecimal, setIsDecimal] = useState(["FALSE", "TRUE"]);
    const [selectedisdecimal, setSelectedIsDecimal] = useState("FALSE");
    const [customerType, setCustomerType] = useState(["ALL", "CustomerList"]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [isCustomerList, setIsCustomerList] = useState(false);
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("");
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [isAllCustomer, setIsAllCustomer] = useState(false);
    var intMPlist: channellist[] = new Array<channellist>();
    const [mplist, Setmplist] = useState(intMPlist);
    const [modelTypeId, setModelTypeId] = useState<any>(null);
    const [attributeId, setAttributeId] = useState(0);
    const [showAddCondition, setShowAddCondition] = useState(true);
    var intallruletype: channellist[] = new Array<channellist>();
    const [existingRule, setExistingRule] = useState(intallruletype);
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions>({
        types: [],
        attributes: [],
        conditions: [],
        values: []
    });
    var activeclassname = "nav-link active";
    var inactiveclassname = "nav-link";
    const backUserDB = () => {
        history.push('/userdashboard');
    }
    useEffect(() => {
        GetAllMarketplaces(function (result: any) {
            Setmplist(result);
            console.log("mplist....", result)
        })

    }, []);
    useEffect(() => {
        GetOrderRuleModelType(function (result: any) {
            console.log("orderModelType....", result)
            setDropdownOptions((prevOptions) => ({
                ...prevOptions,
                types: result
            }));

        })

    }, []);

    useEffect(() => {
        GetExistingOrderRules(function (result: any) {
            setExistingRule(result);
            console.log("existingRule....", existingRule)
        })



    }, [])
    console.log("dropdownOptions....1", dropdownOptions.types)
    // useEffect(() => {
    //     GetOrderRuleAttributes(1, function (result: any) {
    //         // console.log("orderAttributes....",result)

    //     })

    // }, []);

    // useEffect(() => {
    //     GetOrderRuleOperators(1, 1, function (result: any) {
    //         // console.log("orderOperators....",result)

    //     })

    // }, []);



    // Handle changes in dropdown selection for a specific set
    const handleDropdownChange = (index: number, key: string, value: any) => {
        console.log("index.....", index)
        // if (key === "condition") {
        //     console.log("dropdownOptions....4444", dropdownOptions.conditions)
        //     const result = dropdownOptions.conditions.find(item => item.name === value);
        //     const operatorValue: any = result ? result.operator : null
        //     console.log("operatorValue....", operatorValue)

        //     const updatedConditions = conditions.map((condition, i) =>
        //         i === index ? { ...condition, [key]: operatorValue } : condition
        //     );
        //     setConditions(updatedConditions);

        // } else {
        const updatedConditions = conditions.map((condition, i) =>
            i === index ? { ...condition, [key]: value } : condition
        );
        setConditions(updatedConditions);
        //}






        if (key === "type") {

            console.log("dropdownOptions....22222", dropdownOptions.types)
            const result = dropdownOptions.types.find(item => item.name === value);
            const idValue: any = result ? result.id : null
            console.log("idValue....2222", idValue)
            setModelTypeId(idValue);


            GetOrderRuleAttributes(idValue, function (result: any) {
                // console.log("orderAttributes....",result)
                setDropdownOptions((prevOptions) => ({
                    ...prevOptions,
                    attributes: result
                }));

            })
            // setAttributeId(value)

        }
        if (key === "attribute") {
            console.log("value.....3333", value)
            console.log("dropdownOptions....33333", dropdownOptions.attributes)
            const result = dropdownOptions.attributes.find(item => item.name === value);
            const idValue: any = result ? result.id : null

            console.log("idValue....3333", idValue)

            GetOrderRuleOperators(modelTypeId, idValue, function (result: any) {
                // console.log("orderOperators....",result)
                setDropdownOptions((prevOptions) => ({
                    ...prevOptions,
                    conditions: result
                }));

            })


        }


    };
    // Add a new set of dropdowns with a textbox for "Value"
    const handleAddCondition = () => {

        setConditions([
            ...conditions,
            { type: "", attribute: "", condition: "", value: "" }
        ]);
        setShowAddCondition(false);
    };
    // Handle changes in dropdown selection or textbox input for a specific set
    const handleInputChange = (index: number, key: string, value: string) => {
        const updatedConditions = conditions.map((condition, i) =>
            i === index ? { ...condition, [key]: value } : condition
        );
        setConditions(updatedConditions);



        if (key === "type") {

        }

    };
    //console.log("conditions....", conditions);
    const handleStartDate = (e: any) => {

        setStartDate(e.target.value);
    };
    const handleEndtDate = (e: any) => {

        setEndDate(e.target.value);
    };
    const handleCustomerTypeChange = (e: any) => {
        setSelectedCustomer(e.target.value);
        if (e.target.value == "CustomerList") {
            setIsCustomerList(true);
            setIsAllCustomer(false);
        } else if (e.target.value == "ALL") {
            setIsCustomerList(false);
        }
        // e.target.value == "CustomerList" ? setIsCustomerList(true) : setIsCustomerList(false);
        console.log("cust....", e.target.value)


    }
    const onSelectCustomers = (selectedList: any, selectedItem: any) => {
        console.log("Selected item:", selectedList);
        const customers = selectedList.map((item: any) => item.name);
        setSelectedCustomers(customers);
        console.log("Selected customerIds:", customers);

    }
    const onRemoveCustomers = (selectedList: any, selectedItem: any) => {
        console.log("removed item:", selectedList);
        const customers = selectedList.map((item: any) => item.customerId);
        setSelectedCustomers(customers);
        console.log("removed customerIds:", customers);

    }
    const handleChange = (e: any) => {
        if (e.target.id == 1) {
            setactiveprodclass(true);
            setactiveEprdclass(false);

        }
        if (e.target.id == 2) {
            setactiveprodclass(false);
            setactiveEprdclass(true);
            //RefreshProductRules();
        }

    }
    const applyRule = () => {

        const ruleObj = {
            ruleType: ruleType,
            description: description,
            priority: priority,
            startDate: startDate,
            endDate: endDate,
            code: selectedCode,
            isDecimal: selectedisdecimal,
            isAllCustomer: isAllCustomer,
            customers: selectedCustomer,
            conditions: conditions

        }
        console.log("ruleData....", ruleObj);
        console.log("mplist....", selectedCustomers);

        // Create the new array by mapping over customers
        const orderRule = selectedCustomers.map((customer) => ({
            customerId: customer,
            ruleType: ruleObj.ruleType,
            description: ruleObj.description,
            priority: ruleObj.priority,
            startDate: ruleObj.startDate,
            endDate: ruleObj.endDate,
            code: ruleObj.code,
            isDecimal: ruleObj.isDecimal,
            isAllCustomer: ruleObj.isAllCustomer,
            conditions: ruleObj.conditions.map(condition => ({
                ...condition,
                ActionValues: [customer],  // Use customer name in ActionValues
                //value: customer === "Amazon" ? "true" : "1000" // Adjust the condition value based on the customer
            }))
        }));
        console.log("OrderRule...........", orderRule);

    }



    return (
        <div className="orderrules-section">
            <div className="row">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Fulfillment Customers</a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Order Rules
                            </li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-section">
                                <h1>Order Rules</h1>
                                <div>
                                    <button type="button" className="btn btn-primary float-end" onClick={backUserDB}>Back</button>
                                </div>
                            </div>
                             <div className="row">
                                <div className="col-4">
                                    <div className="row g-2 mb-3 align-items-center">
                                        <div className="col-5">
                                            <label className="form-label ff-i fs-16 fw-b">
                                                Choose Rule Type: <span className="required">*</span>
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <select className="form-select" value={ruleType} onChange={(e) => setRuleType(e.target.value)}>
                                                <option value="">Select</option>
                                                <option value="Hold">Hold</option>
                                                <option value="Split">Split</option>
                                                <option value="Route">Route</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> 

                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <button className={activeprodclass == true ? activeclassname : inactiveclassname} aria-current="page" id="1" onClick={handleChange}>New Rule</button>
                                        </li>
                                        <li className="nav-item">
                                            <button id="2" className={activeEprdclass == true ? activeclassname : inactiveclassname} onClick={handleChange}>Existing Rules</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={activeprodclass == true ? "" : "hidden"}>
                                <div className="tab-content">
                                    <h2 className="ff-i fw-m pb-2 primarycolor">Create New Rule</h2>

                                    <div className="row">
                                        {/* <div className="col-6">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-4">
                                                    <label className="form-label ff-i fs-16 fw-b">
                                                        Choose Rule Type: <span className="required">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-8">
                                                    <select className="form-select" value={ruleType} onChange={(e) => setRuleType(e.target.value)}>
                                                        <option value="">Select</option>
                                                        <option value="Hold">Hold</option>
                                                        <option value="Split">Split</option>
                                                        <option value="Route">Route</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-6">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-2">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Rule Name: <span className="required">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-10">
                                                    <input type="text" value={ruleName} onChange={(e) => setRuleName(e.target.value)} className="form-control" aria-describedby="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-2">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Description:
                                                    </label>
                                                </div>
                                                <div className="col-10">
                                                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" aria-describedby="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-3">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Customer<span className="required">*</span>:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select className="form-select form-select-lg" value={selectedCustomer} onChange={handleCustomerTypeChange}>
                                                        <option selected value="">Select</option>
                                                        {
                                                            customerType.map((option, index) =>
                                                                <option key={index} value={option}>{option}</option>
                                                            )

                                                        }
                                                        {/* <option selected>Select</option>
                                                    <option value="1">Customer 1</option>
                                                    <option value="2">Customer 2</option>
                                                    <option value="3">Customer 3</option>
                                                    <option value="3">Customer 4</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-3">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Priority:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input type="text" value={priority} onChange={(e) => setPriority(e.target.value)} className="form-control" aria-describedby="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-6">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Effective Start Date:
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <input type="date" value={startDate} className="form-control" aria-describedby="" onChange={handleStartDate} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-6">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Effective End Date:
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <input type="date" value={endDate} className="form-control" aria-describedby="" onChange={handleEndtDate} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isCustomerList ? (
                                            <div className="row">
                                                <div className="col-3 mb-3">
                                                    <Multiselect
                                                        options={mplist}
                                                        displayValue="name"
                                                        onSelect={onSelectCustomers}
                                                        onRemove={onRemoveCustomers}
                                                    />
                                                </div>

                                            </div>

                                        ) : (null)
                                    }

                                    <h3 className="ff-i fs-18 fw-m pb-2 primarycolor">Apply the rule only to items matching the following conditions:</h3>
                                    {/* <p className="ff-i fs-16 fw-r ls-05">If ALL of these conditions are FALSE:</p> */}
                                    <div className="row align-items-center mb-3">
                                        <div className="col-auto">
                                            <label className="col-form-label">If</label>
                                        </div>
                                        <div className="col-2">
                                            <select className="form-select" value={selectedCode} onChange={(e) => setSelectedCode(e.target.value)}>
                                                {code.map((optionVal, index) => (
                                                    <option key={index} value={optionVal}>
                                                        {optionVal}
                                                    </option>

                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-auto">
                                            <label className="col-form-label">of these conditions are</label>
                                        </div>
                                        <div className="col-2">
                                            <select className="form-select" value={selectedisdecimal} onChange={(e) => setSelectedIsDecimal(e.target.value)}>
                                                {
                                                    isDecimal.map((optionVal, index) => (
                                                        <option key={index} value={optionVal}>
                                                            {optionVal}
                                                        </option>

                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                    <div className="col-12">
                                        <div className="addcondition-div" onClick={handleAddCondition}>
                                            <span className="plus-icon"><i className="bi bi-plus-circle"></i></span> Add Condition
                                        </div>
                                    </div>
                                </div> */}
                                    {
                                        conditions.map((condition: any, index: any) => (
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="mb-4">
                                                        <label className="form-label ff-i fs-14 fw-b">Type</label>
                                                        <select className="form-select form-select-lg" value={condition.type}
                                                            onChange={(e) => handleDropdownChange(index, "type", e.target.value)}>
                                                            <option value="" disabled>Select Type</option>
                                                            {dropdownOptions.types.map((type) => (
                                                                <option key={type.id} value={type.name}>{type.name} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <label className="form-label ff-i fs-14 fw-b">Attribute</label>
                                                    <select className="form-select form-select-lg" value={condition.attributes}
                                                        onChange={(e) => handleDropdownChange(index, "attribute", e.target.value)}>
                                                        <option value="" >Select Attribute</option>
                                                        {dropdownOptions.attributes.map((attribute) => (
                                                            <option key={attribute.id} value={attribute.name}>{attribute.name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                                <div className="col-3">
                                                    <label className="form-label ff-i fs-14 fw-b">Operator</label>
                                                    <select className="form-select form-select-lg" value={condition.condition}
                                                        onChange={(e) => handleDropdownChange(index, "condition", e.target.value)}>
                                                        <option value="" >Select Operator</option>
                                                        {dropdownOptions.conditions.map((cond) => (
                                                            <option key={cond.id} value={cond.name}>{cond.name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                                <div className="col-3">
                                                    <label className="form-label ff-i fs-14 fw-b">Value</label>
                                                    <input type="text" className="form-control" aria-describedby="" value={condition.value} onChange={(e) => handleInputChange(index, "value", e.target.value)} />
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {/* <div className="row">
                                    <div className="col-3">
                                        <div className="mb-4">
                                            <label className="form-label ff-i fs-14 fw-b">Type</label>
                                            <select class="form-select form-select-lg">
                                                <option selected>Choose Type</option>
                                                <option value="1">Order</option>
                                                <option value="2">Customer</option>
                                                <option value="3">Product</option>
                                                <option value="3">Branch</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <label className="form-label ff-i fs-14 fw-b">Add Attribute</label>
                                        <select class="form-select form-select-lg">
                                            <option selected>Choose Attributes</option>
                                            <option value="1">Total</option>
                                            <option value="2">Price</option>
                                            <option value="3">SKU</option>
                                            <option value="4">Order No</option>
                                            <option value="5">Name</option>
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <label className="form-label ff-i fs-14 fw-b">Add Condition</label>
                                        <select class="form-select form-select-lg">
                                            <option selected>Choose Attributes</option>
                                            <option value="1">Greater than</option>
                                            <option value="2">Less than</option>
                                            <option value="3">Equals to</option>
                                            <option value="3">Contains</option>
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <label className="form-label ff-i fs-14 fw-b">Value</label>
                                        <input type="text" class="form-control" aria-describedby="" />
                                    </div>
                                </div> */}
                                    {showAddCondition ?
                                        (<div className="row">
                                            <div className="col-12">
                                                <div className="addcondition-div" >
                                                    <span className="plus-icon" onClick={handleAddCondition}><i className="bi bi-plus-circle"></i></span> Add Condition
                                                </div>
                                            </div>
                                        </div>) : (null)

                                    }

                                    {/* <div className="mt-4 mb-3">
                                        <button type="button" className="btn btn-primary btn-lg select-btn">Select</button>
                                    </div> */}
                                    {/* <div className="orderrules-table">
                                        <table className="table table-bordered">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        ID
                                                        <select className="form-select mt-3">
                                                            <option selected>All IDs</option>
                                                            <option value="1">18</option>
                                                            <option value="2">19</option>
                                                            <option value="3">20</option>
                                                        </select>
                                                    </th>
                                                    <th>
                                                        Type
                                                        <select className="form-select mt-3">
                                                            <option selected>All Types</option>
                                                            <option value="1">Simple Product</option>
                                                            <option value="2">Simple Product 1</option>
                                                            <option value="3">Simple Product 2</option>
                                                        </select>
                                                    </th>
                                                    <th>
                                                        Attribute Set
                                                        <select className="form-select mt-3">
                                                            <option selected>All Attribute Sets</option>
                                                            <option value="1">Backflow</option>
                                                            <option value="2">Backflow 1</option>
                                                            <option value="3">Backflow 2</option>
                                                        </select>
                                                    </th>
                                                    <th>
                                                        SKU
                                                        <select className="form-select mt-3">
                                                            <option selected>All SKUs</option>
                                                            <option value="1">FEU765-100</option>
                                                            <option value="2">FEU765-100</option>
                                                            <option value="3">FEU765-100</option>
                                                        </select>
                                                    </th>
                                                    <th>
                                                        Product
                                                        <select className="form-select mt-3">
                                                            <option selected>All Products</option>
                                                            <option value="1">Product 1</option>
                                                            <option value="2">Product 2</option>
                                                            <option value="3">Product 3</option>
                                                        </select>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> */}
                                    <div className="mt-4">
                                        <button type="button" className="btn btn-primary btn-lg apply-btn" onClick={applyRule}>Apply</button>
                                    </div>
                                </div>

                            </div>

                            <div className={activeEprdclass == true ? "" : "hidden"}>
                                <div className="tab-content">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="table-scroll-section">
                                                <table className="table table-bordered">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">
                                                                Rule ID
                                                            </th>
                                                            <th scope="col">
                                                                Rule Name
                                                            </th>
                                                            <th scope="col">
                                                                Description
                                                            </th>
                                                            <th scope="col">
                                                                Enabled
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            existingRule != null ? (
                                                                existingRule.map((item, index) => (
                                                                    <tr>
                                                                        <td>
                                                                            {item.resultid}
                                                                        </td>
                                                                        <td>
                                                                            {item.name}
                                                                        </td>
                                                                        <td>
                                                                            {item.message}
                                                                        </td>
                                                                        <td>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" checked={item.isSuccess} id="" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                )
                                                                )

                                                            ) : (null)

                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OrderRulesComponent;